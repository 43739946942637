<template>
  <div>
    <md-card class="disc-cat-report block-bg">
      <md-card-header
        class="md-card-header-text md-card-header-green md-layout md-gutter"
      >
        <div class="md-layout-item md-size-70">
          <span class="arrow_left">
            <router-link :to="{ path: '/dashboard' }">
              <img src="../../../assets/images/left.svg" />
            </router-link>
          </span>
          <h3>{{ $t('Discount Category Report') }}</h3>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout md-layout-item filter-section block-bg">
            <div class="md-layout-item md-size-65 pd-l">
              <date-period-dropdown
                @interval="setDateType"
              ></date-period-dropdown>
            </div>
            <div class="md-layout search-section md-layout-item md-size-35">
              <div
                class="md-layout-item md-size-65"
                v-if="userRole == 'branch_user'"
              >
                <md-field>
                  <label class="text-nowrap">Outlets</label>
                  <md-select
                    v-model="queries.outletId"
                    v-on:input="getDiscountCategories(queries.outletId)"
                    multiple
                  >
                    <md-option
                      v-for="item in outlets"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-size-65">
                <md-field>
                  <label class="text-nowrap">Discount Categories</label>
                  <md-select v-model="queries.cat_id">
                    <md-option
                      v-for="(item, index) in discount_categories"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-size-35">
                <div class="float-right">
                  <md-button class="md-teritiary" @click="discountCatReport"
                    >SEARCH</md-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="md-layout-item md-size-100 position-relative"
            v-if="!loader"
          ></div>
        </div>

        <div class="loader-container" v-if="loader">
          <clip-loader :loading="loader" :color="'#00E0DB'"></clip-loader>
        </div>

        <div class="md-layout" v-if="!loader">
          <div class="md-layout-item report-table">
            <div ref="content" id="mytable">
              <md-table class="table-typ1">
                <md-table-toolbar>
                  <md-button
                    class="md-teritiary download-inv"
                    @click="downloadInvoice"
                    >Downlaod Invoice</md-button
                  >
                  <div class="action-buttons export-btn">
                    <div class="pdf-el">
                      <div class="download-btn">
                        <img
                          src="../../../assets/images/pdf.svg"
                          @click="downloadWithoutCSS()"
                        />
                      </div>
                    </div>

                    <div class="excel-el">
                      <vue-excel-xlsx
                        class="Excel-btn"
                        :data="reports"
                        :columns="columns"
                        :filename="fileName"
                        :sheetname="fileName"
                        :sdate="sdate"
                        :edate="edate"
                      >
                      </vue-excel-xlsx>
                    </div>
                  </div>
                </md-table-toolbar>
                <md-table-row>
                  <md-table-head>Date</md-table-head>
                  <md-table-head>Category Type</md-table-head>
                  <md-table-head>Menu Category</md-table-head>
                  <md-table-head>Menu Item</md-table-head>
                  <md-table-head>Quantity</md-table-head>
                  <md-table-head>Price</md-table-head>
                  <md-table-head>Gross</md-table-head>
                  <md-table-head>Discount</md-table-head>
                  <md-table-head>Sales Tax</md-table-head>
                  <md-table-head>Net Sales</md-table-head>
                </md-table-row>
                <slot v-for="items in reports">
                  <md-table-row
                    :class="[
                      {
                        'bold-total':
                          items.menu_item == 'Overall Total' ||
                          items.menu_item == 'Sub Total',
                      },
                    ]"
                  >
                    <!-- <md-table-cell>{{
                      items.date
                        ? moment.utc(items.date).format('DD/MM/YYYY H:mm')
                        : ''
                    }}</md-table-cell> -->
                    <md-table-cell>{{ items.date }}</md-table-cell>
                    <md-table-cell>{{ items.category_type }}</md-table-cell>
                    <md-table-cell>{{ items.menu_category }}</md-table-cell>
                    <md-table-cell>{{ items.menu_item }}</md-table-cell>
                    <md-table-cell>{{ items.quantity }}</md-table-cell>
                    <md-table-cell>{{ items.price }}</md-table-cell>
                    <md-table-cell>{{ items.gross }}</md-table-cell>
                    <md-table-cell>{{ items.discount }}</md-table-cell>
                    <md-table-cell>{{ items.sales_tax }}</md-table-cell>
                    <md-table-cell>{{ items.net_sle }}</md-table-cell>
                  </md-table-row>
                </slot>
              </md-table>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { ChartCard } from '@/components'
import { accountService } from '../../../services/account.js'
import { reportService } from '../../../services/reports.js'
import { pdfService } from '../../../services/jspdf.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import autoTable from 'jspdf-autotable'
import DateTimePicker from '../../../components/DateTimePicker.vue'
import datetime from 'vuejs-datetimepicker'
import { customizeService } from '../../../services/customize.js'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DatePeriodDropdown from '../../../components/DatePeriodDropdown'
import VueExcelXlsx from '../../../components/VueExcelXlsx'

export default {
  components: {
    DateTimePicker,
    datetime,
    ClipLoader,
    DatePeriodDropdown,
    VueExcelXlsx,
  },
  data() {
    return {
      datacollection: null,
      reports: [],
      search: null,
      searched: [],
      sdate: '',
      edate: '',
      columns: [
        {
          field: 'date',
        },
        {
          field: 'category_type',
        },
        {
          field: 'menu_category',
        },
        {
          field: 'menu_item',
        },
        {
          field: 'net_sle',
        },
        {
          field: 'quantity',
        },
        {
          field: 'price',
        },
        {
          field: 'gross',
        },
        {
          field: 'discount',
        },
        {
          field: 'sales_tax',
        },
        {
          field: 'net_sle',
        },
      ],
      queries: {
        cat_id: 0,
        outletId: [],
        search_type: 'Daily',
      },
      discount_categories: [],
      cat_id: 44,
      outlets: [],
      userRole: '',
      loader: false,
      fileName: '',
    }
  },

  methods: {
    fromDateTime(date) {
      this.queries.startDate = date
    },

    toDateTime(date) {
      this.queries.endDate = date
    },

    getDiscountCategories(outlet_id) {
      if (outlet_id != null) {
        //outlet_id = null when initialized
        customizeService.getDiscountCat(outlet_id).then((res) => {
          this.discount_categories = res.data.discount_categories
          this.discount_categories.unshift({ id: 'undefined', name: 'All' })
          this.discount_categories.push({ id: 0, name: 'Manual Discount' })
        })
      }
    },

    fillData() {
      this.datacollection = {
        labels: [
          '1:00',
          '2:00',
          '3:00',
          '4:00',
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '9:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '00:00',
        ],
        datasets: [
          {
            label: 'Total Sales',
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0,
            ],
            fill: 'false',
          },
        ],
      }
    },

    arrangeForPdf() {
      var body = []
      this.reports.forEach((el) => {
        let arrayObj = [
          el.date,
          el.category_type,
          el.menu_category,
          el.menu_item,
          el.quantity,
          el.price,
          el.gross,
          el.discount,
          el.sales_tax,
          el.net_sle,
        ]
        body.push(arrayObj)
      })
      return body
    },

    arrangeForInvoicePdf(data) {
      var body = []
      data.forEach((el) => {
        let arrayObj = [el.date, el.category_name, el.quantity, el.price]
        body.push(arrayObj)
      })
      return body
    },
    selectedOutletsNames() {
      let selectedOutlets = this.outlets
        .filter((item) => this.queries.outletId.includes(item.id))
        .map((item) => item.name)
        .join()
      return selectedOutlets
    },
    downloadWithoutCSS() {
      let body = this.arrangeForPdf()
      this.queries.startDate = this.sdate
      this.queries.endDate = this.edate
      const doc = new jsPDF({ orientation: 'landscape', format: 'a4' })
      let selectedOutlets = this.selectedOutletsNames()

      let head = [
        [
          'Date',
          'Cat Type',
          'Menu Cat',
          'Menu Item',
          'Qty',
          'Price',
          'Gross',
          'Disc',
          'Sales Tax',
          'Net Sales',
        ],
      ]
      pdfService.headerFooterConfig(
        this.queries,
        doc,
        'Discount Categories Report',
        selectedOutlets
      )
      pdfService.autoTableConfig(doc, head, body, {}, this.fileName + '.pdf')
    },

    downloadInvoice() {
      reportService.getDiscountCatInvoice(this.queries).then((res) => {
        let body = this.arrangeForInvoicePdf(res.data.result)
        this.queries.startDate = res.data.sdate
        this.queries.endDate = res.data.edate

        this.sdate = res.data.sdate
        this.edate = res.data.edate

        let cat = this.discount_categories.find(
          (el) => el.id == this.queries.cat_id
        )

        const doc = new jsPDF({ orientation: 'portrait', format: 'a4' })
        let selectedOutlets = this.selectedOutletsNames()

        let head = [['Date', 'Category Name', 'Quantity', 'Price']]
        pdfService.headerFooterConfig(
          this.queries,
          doc,
          'Discount Categories Invoice',
          selectedOutlets
        )
        pdfService.autoTableConfig(
          doc,
          head,
          body,
          {},
          'discount-category-invoice-' + cat.name + '.pdf',
          12
        )
      })
    },

    setDateType(interval) {
      this.queries.search_type = interval.type
      if (interval.type == 'Ad Hoc') {
        this.queries.startDate = interval.startDate
        this.queries.endDate = interval.endDate
      } else {
        this.queries.startDate = interval.date
        this.queries.endDate = undefined
        if (!this.loader) {
          this.discountCatReport()
        }
      }
    },

    discountCatReport() {
      this.loader = true
      reportService.getDiscountCategoryReport(this.queries).then((res) => {
        this.loader = false
        this.reports = res.data.result
        this.sdate = res.data.sdate
        this.edate = res.data.edate

        let cat = this.discount_categories.find(
          (el) => el.id == this.queries.cat_id
        )
        this.fileName = 'Discount Category Report ' + '- ' + cat.name
      })
    },
  },

  created() {
    // this.searched = this.users
    this.userRole = accountService.userRole()
    this.loader = true
    if (accountService.userRole() == 'branch_user') {
      customizeService.getOutlets().then((res) => {
        this.outlets = res.data.outlets
        this.queries.outletId = res.data.outlets.map((outlet) => {
          return outlet.id
        })
        this.discountCatReport()
      })
    } else {
      this.getDiscountCategories(accountService.getOutletId())
      this.discountCatReport()
    }
  },

  mounted() {
    this.fillData()
  },
}
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}

.md-table-head-container {
  text-align: left;
}

.bold-total {
  font-weight: bold;
}

.order-type-bold {
  font-size: 18px;
  font-weight: bold;
}

.download-buttons {
  position: absolute;
  right: 0;
}
.download-inv {
  position: absolute;
  right: 85px;
  top: 12px;
}
</style>
